import { useEffect, useState } from "react";
import "./Portfolio.css";
import VanillaTilt from "vanilla-tilt";
import { useInView } from "react-intersection-observer";
import { isSafari, isFirefox } from "react-device-detect";

function Portfolio() {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const [screenHeight, setScreenHeight] = useState(window.screen.height);
  // const [isTilt, setIsTilt] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.screen.width);
      setScreenHeight(window.screen.height);
    };
    window.addEventListener("resize", handleResize);
  }, [screenWidth, screenHeight]);

  const { ref: porTitle, inView: porTitleView } = useInView({
    threshold: 0.25,
    triggerOnce: true,
  });

  const { ref: projectCon, inView: projectConView } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectCon2, inView: projectConView2 } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectCon3, inView: projectConView3 } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectH1, inView: projectH1View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectH2, inView: projectH2View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectH3, inView: projectH3View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectText1, inView: projectText1View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectText2, inView: projectText2View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectText3, inView: projectText3View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: project1, inView: project1View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: project2, inView: project2View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: project3, inView: project3View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectGo1, inView: projectGo1View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectGo2, inView: projectGo2View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectGo3, inView: projectGo3View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectName1, inView: projectName1View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectName2, inView: projectName2View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectName3, inView: projectName3View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectInfo, inView: projectInfoView } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectInfo2, inView: projectInfo2View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectInfo3, inView: projectInfo3View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectImg, inView: projectImgView } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectImg2, inView: projectImg2View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: projectImg3, inView: projectImg3View } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  useEffect(() => {
    if (screenWidth > 1200 && !isSafari) {
      VanillaTilt.init(document.querySelectorAll(".tilt-con"), {
        max: 15,
        speed: 100,
        glare: true,
        "max-glare": 0.5,
      });
    }
  }, [screenWidth]);

  return (
    <div className="portfolio-page">
      <div className="portfolio-con">
        <div
          ref={porTitle}
          className={
            porTitleView ? "portfolio-title" : "portfolio-title-before"
          }
          id="title-flash"
        >
          <h1>Portfolio</h1>
        </div>
        <div className="project-cards">
          <div
            ref={projectCon}
            className={
              projectConView ? "project-card-con" : "project-card-con-before"
            }
          >
            <div
              className={
                !isSafari && screenWidth > 1200
                  ? "tilt-con"
                  : screenWidth <= 1200 || isSafari
                  ? "non-tilt-con"
                  : "tilt-con"
              }
            >
              <div
                ref={projectH1}
                className={
                  projectH1View && !isFirefox && !isSafari
                    ? "project-card-h1"
                    : projectH1View && !isFirefox && isSafari
                    ? "project-card-h1-ios"
                    : projectH1View && !isSafari && isFirefox
                    ? "project-card-h1-fx"
                    : !projectH1View && !isFirefox && !isSafari
                    ? "project-card-h1-before"
                    : !projectH1View && !isFirefox && isSafari
                    ? "project-card-h1-ios-before"
                    : !projectH1View && !isSafari && isFirefox
                    ? "project-card-h1-fx-before"
                    : "project-card-h1-before"
                }
              >
                <h5
                  ref={projectName1}
                  className={
                    projectName1View && !isSafari
                      ? "project-h1-name"
                      : projectName1View && isSafari
                      ? "project-h1-name-ios"
                      : !projectName1View && !isSafari
                      ? "project-h1-name-before"
                      : !projectName1View && isSafari
                      ? "project-h1-name-ios-before"
                      : "project-h1-name-before"
                  }
                >
                  NC News API
                </h5>
              </div>
              <div
                ref={projectText1}
                className={
                  projectText1View && !isFirefox && !isSafari
                    ? "project-text"
                    : projectText1View && !isFirefox && isSafari
                    ? "project-text-ios"
                    : projectText1View && !isSafari && isFirefox
                    ? "project-text-fx"
                    : !projectText1View && !isFirefox && !isSafari
                    ? "project-text-before"
                    : !projectText1View && isSafari && !isFirefox
                    ? "project-text-ios-before"
                    : !projectText1View && !isSafari && isFirefox
                    ? "project-text-fx-before"
                    : "project-text-before"
                }
              >
                <p
                  ref={projectInfo}
                  className={
                    projectInfoView
                      ? "project-text-op"
                      : "project-text-op-before"
                  }
                >
                  <span>
                    An API to provide, add, update and remove application data
                    programmatically to the frontend client.
                  </span>
                </p>
              </div>
              <div
                ref={projectGo1}
                className={
                  projectGo1View && !isSafari
                    ? "visit-button"
                    : projectGo1View && isSafari
                    ? "visit-button-ios"
                    : !projectGo1View && !isSafari
                    ? "visit-button-before"
                    : !projectGo1View && isSafari
                    ? "visit-button-ios-before"
                    : "visit-button-before"
                }
              >
                <a
                  className={!isSafari ? "go-button" : "go-button-ios"}
                  href="https://nc-news-app-issy.herokuapp.com/api"
                  title="NC News API"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>GO</div>
                </a>
              </div>
              <div
                ref={project1}
                className={
                  project1View && !isFirefox && !isSafari
                    ? "project"
                    : project1View && isSafari && !isFirefox
                    ? "project-ios"
                    : project1View && isFirefox && !isSafari
                    ? "project-fx"
                    : !project1View && !isFirefox && !isSafari
                    ? "project-before"
                    : !project1View && !isFirefox && isSafari
                    ? "project-before-ios"
                    : project1View && !isSafari && isFirefox
                    ? "project-fx-before"
                    : "project-before"
                }
              >
                <div
                  ref={projectImg}
                  className={
                    projectImgView ? "project-img" : "project-img-before"
                  }
                ></div>
              </div>
              <div className="project-card"></div>
            </div>
          </div>
          <div
            ref={projectCon2}
            className={
              projectConView2 ? "project-card-con" : "project-card-con-before"
            }
          >
            <div className={screenWidth > 1200 ? "tilt-con" : "non-tilt-con"}>
              <div
                ref={projectH2}
                className={
                  projectH2View && !isFirefox && !isSafari
                    ? "project-card-h1-2"
                    : projectH2View && !isFirefox && isSafari
                    ? "project-card-h1-ios-2"
                    : projectH2View && !isSafari && isFirefox
                    ? "project-card-h1-fx-2"
                    : !projectH2View && !isFirefox && !isSafari
                    ? "project-card-h1-2-before"
                    : !projectH2View && !isFirefox && isSafari
                    ? "project-card-h1-ios-2-before"
                    : !projectH2View && !isSafari && isFirefox
                    ? "project-card-h1-fx-2-before"
                    : "project-card-h1-2-before"
                }
              >
                <h5
                  ref={projectName2}
                  className={
                    projectName2View && !isSafari
                      ? "project-h1-name-2"
                      : projectName2View && isSafari
                      ? "project-h1-name-ios-2"
                      : !projectName2View && !isSafari
                      ? "project-h1-name-before"
                      : !projectName2View && isSafari
                      ? "project-h1-name-ios-before"
                      : "project-h1-name-before"
                  }
                >
                  NC News APP
                </h5>
              </div>
              <div
                ref={projectText2}
                className={
                  projectText2View && !isFirefox && !isSafari
                    ? "project-text-2"
                    : projectText2View && !isFirefox && isSafari
                    ? "project-text-ios-2"
                    : projectText2View && !isSafari && isFirefox
                    ? "project-text-fx-2"
                    : !projectText2View && !isFirefox && !isSafari
                    ? "project-text-2-before"
                    : !projectText2View && isSafari && !isFirefox
                    ? "project-text-ios-2-before"
                    : !projectText2View && !isSafari && isFirefox
                    ? "project-text-fx-2-before"
                    : "project-text-2-before"
                }
              >
                <p
                  ref={projectInfo2}
                  className={
                    projectInfo2View
                      ? "project-text-op-2"
                      : "project-text-op-before"
                  }
                >
                  <span>
                    A React.js App which uses NC News API to provide news
                    articles, comments, votes and dates on the related articles.
                  </span>
                </p>
              </div>
              <div
                ref={projectGo2}
                className={
                  projectGo2View && !isSafari
                    ? "visit-button-2"
                    : projectGo2View && isSafari
                    ? "visit-button-ios-2"
                    : !projectGo2View && !isSafari
                    ? "visit-button-before"
                    : !projectGo2View && isSafari
                    ? "visit-button-ios-before"
                    : "visit-button-before"
                }
              >
                <a
                  className={!isSafari ? "go-button" : "go-button-ios"}
                  href="https://the-awesome-issy786-site.netlify.app/"
                  title="NC News React App"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>GO</div>
                </a>
              </div>
              <div
                ref={project2}
                className={
                  project2View && !isFirefox && !isSafari
                    ? "project-2"
                    : project2View && isSafari && !isFirefox
                    ? "project-ios-2"
                    : project2View && isFirefox && !isSafari
                    ? "project-fx-2"
                    : !project2View && !isFirefox && !isSafari
                    ? "project-before"
                    : !project2View && !isFirefox && isSafari
                    ? "project-before-ios"
                    : project2View && !isSafari && isFirefox
                    ? "project-fx-before"
                    : "project-before"
                }
              >
                <div
                  ref={projectImg2}
                  className={
                    projectImg2View ? "project-img-2" : "project-img-before"
                  }
                ></div>
              </div>
              <div className="project-card"></div>
            </div>
          </div>
          <div
            ref={projectCon3}
            className={
              projectConView3 ? "project-card-con" : "project-card-con-before"
            }
          >
            <div className={screenWidth > 1200 ? "tilt-con" : "non-tilt-con"}>
              <div
                ref={projectH3}
                className={
                  projectH3View && !isFirefox && !isSafari
                    ? "project-card-h1-3"
                    : projectH3View && !isFirefox && isSafari
                    ? "project-card-h1-ios-3"
                    : projectH3View && !isSafari && isFirefox
                    ? "project-card-h1-fx-3"
                    : !projectH3View && !isFirefox && !isSafari
                    ? "project-card-h1-3-before"
                    : !projectH3View && !isFirefox && isSafari
                    ? "project-card-h1-ios-3-before"
                    : !projectH3View && !isSafari && isFirefox
                    ? "project-card-h1-fx-3-before"
                    : "project-card-h1-3-before"
                }
              >
                <h5
                  ref={projectName3}
                  className={
                    projectName3View && !isSafari
                      ? "project-h1-name-3"
                      : projectName2View && isSafari
                      ? "project-h1-name-ios-3"
                      : !projectName2View && !isSafari
                      ? "project-h1-name-before"
                      : !projectName2View && isSafari
                      ? "project-h1-name-ios-before"
                      : "project-h1-name-before"
                  }
                >
                  Scan N Pan
                </h5>
              </div>
              <div
                ref={projectText3}
                className={
                  projectText3View && !isFirefox && !isSafari
                    ? "project-text-3"
                    : projectText3View && !isFirefox && isSafari
                    ? "project-text-ios-3"
                    : projectText3View && !isSafari && isFirefox
                    ? "project-text-fx-3"
                    : !projectText3View && !isFirefox && !isSafari
                    ? "project-text-3-before"
                    : !projectText3View && isSafari && !isFirefox
                    ? "project-text-ios-3-before"
                    : !projectText3View && !isSafari && isFirefox
                    ? "project-text-fx-3-before"
                    : "project-text-3-before"
                }
              >
                <p
                  ref={projectInfo3}
                  className={
                    projectInfo3View
                      ? "project-text-op-3"
                      : "project-text-op-before"
                  }
                >
                  <span>
                    A React Native App that allows users to enter ingredients to
                    fetch all the recipes which include the inputted
                    ingredients.
                  </span>
                </p>
              </div>
              <div
                ref={projectGo3}
                className={
                  projectGo3View && !isSafari
                    ? "visit-button-3"
                    : projectGo3View && isSafari
                    ? "visit-button-ios-3"
                    : !projectGo3View && !isSafari
                    ? "visit-button-before"
                    : !projectGo3View && isSafari
                    ? "visit-button-ios-before"
                    : "visit-button-before"
                }
              >
                <a
                  className={!isSafari ? "go-button" : "go-button-ios"}
                  href="https://github.com/Issy786/scan-n-pan"
                  title="Scan N Pan App"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>GO</div>
                </a>
              </div>
              <div
                ref={project3}
                className={
                  project3View && !isFirefox && !isSafari
                    ? "project-3"
                    : project3View && isSafari && !isFirefox
                    ? "project-ios-3"
                    : project3View && isFirefox && !isSafari
                    ? "project-fx-3"
                    : !project3View && !isFirefox && !isSafari
                    ? "project-before"
                    : !project3View && !isFirefox && isSafari
                    ? "project-before-ios"
                    : project3View && !isSafari && isFirefox
                    ? "project-fx-before"
                    : "project-before"
                }
              >
                <div
                  ref={projectImg3}
                  className={
                    projectImg3View ? "project-img-3" : "project-img-before"
                  }
                ></div>
              </div>
              <div className="project-card"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
