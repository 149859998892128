import React, { useCallback, useEffect, useState } from "react";
import { gsap } from "gsap";
import "./NavBar.css";
import { Link } from "react-scroll";
import { click } from "@testing-library/user-event/dist/click";

function NavBar() {
  const [show, setShow] = useState(true);
  const [scrollPos, setScrollPos] = useState(0);
  const [live, setLive] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.screen.width);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.screen.width);
    };
    window.addEventListener("resize", handleResize);
  }, [screenWidth]);

  const toggleLive = () => {
    setLive(!live);
  };

  const toggleLiveForMobileOnly = () => {
    if (screenWidth < 1200) {
      setLive(!live);
    }
  };

  const handleScroll = useCallback(() => {
    setScrollPos(document.body.getBoundingClientRect().top);
    setShow(document.body.getBoundingClientRect().top > scrollPos);
    setLive(false);
  }, [scrollPos]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.closest(".hamburger-wrapper")) {
        // Ignore clicks on the button that toggles live
        return;
      }

      if (live && !e.target.closest(".ul-mobile")) {
        setLive(!live);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [live]);

  const logo1 = React.createRef();

  useEffect(() => {
    gsap.to(logo1.current, {
      y: 0,
      stagger: 0.05,
      delay: 3.6,
      duration: 0.1,
    });
  }, [logo1]);

  return (
    <nav className={show ? "active" : "hidden"} id="bounce">
      <div className="con">
        <Link to="/" smooth={true} offset={-200} duration={500}>
          <p ref={logo1} className="logo" id="logoSlide">
            ISSY
          </p>
        </Link>
      </div>
      <div className={click ? "nav-menu ul active" : "nav-menu"}>
        <div className={live ? "ul-mobile live" : "ul-mobile"}>
          <div className="li link1">
            <Link
              activeClass="link-active"
              to="/about"
              smooth={true}
              spy={true}
              offset={0}
              duration={500}
              onClick={toggleLiveForMobileOnly}
            >
              About
            </Link>
          </div>

          <div className="li link2">
            <Link
              activeClass="link-active"
              to="/portfolio"
              smooth={true}
              spy={true}
              offset={0}
              duration={500}
              onClick={toggleLiveForMobileOnly}
            >
              Portfolio
            </Link>
          </div>

          <div className="li link3">
            <Link
              activeClass="link-active"
              to="/contact"
              smooth={true}
              spy={true}
              offset={0}
              duration={500}
              onClick={toggleLiveForMobileOnly}
            >
              Contact
            </Link>
          </div>
        </div>
      </div>
      <div
        className={`hamburger-wrapper${live ? " live" : ""}`}
        onClick={toggleLive}
        id="mobile-nav"
      >
        <div className="hamburger-icon">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      {/* </div> */}
    </nav>
  );
}

export default NavBar;
