import "./footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-con">
        <p>
          Built & Designed by Isfahan Khan using{" "}
          <span className="footer-icon">
            <a href="https://react.dev/" target="_blank" rel="noreferrer">
              <i className="fa-brands fa-react"></i>
            </a>
          </span>{" "}
          React
        </p>
      </div>
    </div>
  );
}

export default Footer;
