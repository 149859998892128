import React from "react";
import "./Popup.css";

function Popup(props) {
  return props.trigger ? (
    <div className={props.popupErr === true ? "popup-err" : "popup"}>
      <div className="pop-inner">
        <button className="close-btn" onClick={() => props.setTrigger(false)}>
          x
        </button>
        {props.children}
      </div>
      <div className={props.popupErr === true ? "bar-err" : "bar"}></div>
    </div>
  ) : (
    ""
  );
}

export default Popup;
