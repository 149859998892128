import React, { useRef } from "react";
import { useInView } from "react-intersection-observer";
// import emailjs from "@emailjs/browser";
import "./Contact.css";
import axios from "axios";
// import Popup from "./Popup";

function Contact({ setButtonPopup, setPopupErr }) {
  const { ref: contactConSide, inView: contactConSideView } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  // const { ref: contactCon, inView: contactConView } = useInView({
  //   threshold: 0.15,
  //   triggerOnce: true,
  // });

  const form = useRef();

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs
  //     .sendForm(
  //       "YOUR_SERVICE_ID",
  //       "YOUR_TEMPLATE_ID",
  //       form.current,
  //       "YOUR_PUBLIC_KEY"
  //     )
  //     .then(
  //       (result) => {
  //         setButtonPopup(true);
  //         e.target.reset();
  //         setTimeout(() => {
  //           setButtonPopup(false);
  //         }, 3500); // Timeout in milliseconds (3.5 seconds)
  //         console.log(result.text);
  //         console.log("message sent yo");
  //       },
  //       (error) => {
  //         console.log(error.text);
  //         console.log("error");
  //         setPopupErr(true);
  //         setTimeout(() => {
  //           setPopupErr(false);
  //         }, 3500); // Timeout in milliseconds (3.5 seconds)
  //       }
  //     );
  // };

  // const sendEmail = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const formData = new FormData(form.current);
  //     const data = Object.fromEntries(formData);

  //     const response = await axios.post("/.netlify/functions/sendEmail", data);
  //     console.log(response.data.result, "yo");
  //     setButtonPopup(true);
  //     e.target.reset();
  //     setTimeout(() => {
  //       setButtonPopup(false);
  //     }, 3500); // Timeout in milliseconds (3.5 seconds)
  //   } catch (error) {
  //     console.log(error.response.data.error, "here");
  //     setPopupErr(true);
  //     setTimeout(() => {
  //       setPopupErr(false);
  //     }, 3500); // Timeout in milliseconds (3.5 seconds)
  //   }
  // };

  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    const data = Object.fromEntries(formData.entries());

    try {
      const response = await axios.post("/api/sendgridEmail", data);

      if (response.status === 200) {
        setButtonPopup(true);
        e.target.reset();
        setTimeout(() => {
          setButtonPopup(false);
        }, 3500);
        console.log("message sent");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.log(error.message);
      console.log("error");
      setPopupErr(true);
      setTimeout(() => {
        setPopupErr(false);
      }, 3500);
    }
  };

  return (
    <div className="contact-page">
      <div
        ref={contactConSide}
        className={
          contactConSideView ? "contact-con-side" : "contact-con-side-before"
        }
      >
        <div className="contact-con">
          <div className="contact-me">
            <div className="contact-me-h1-con">
              <h1
                ref={contactConSide}
                className={
                  contactConSideView ? "contact-me-h1" : "contact-me-h1-before"
                }
              >
                Contact <span className="me-text">Me</span>
              </h1>
            </div>
            <form ref={form} onSubmit={sendEmail}>
              <div
                ref={contactConSide}
                className={
                  contactConSideView ? "name-email" : "name-email-before"
                }
              >
                <div className="name-col">
                  <label>
                    Name<span className="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    name="user_name"
                    placeholder="Enter Name"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="name-col">
                  <label>
                    Email<span className="mandatory">*</span>
                  </label>
                  <input
                    type="email"
                    name="user_email"
                    placeholder="Enter Email"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              <div
                ref={contactConSide}
                className={
                  contactConSideView
                    ? "contact-subject"
                    : "contact-subject-before"
                }
              >
                <label>Subject</label>
                <input
                  type="text"
                  name="subject"
                  placeholder="Enter Subject"
                  autoComplete="off"
                />
              </div>
              <div
                ref={contactConSide}
                className={
                  contactConSideView ? "contact-mesg" : "contact-mesg-before"
                }
              >
                <label>
                  Message<span className="mandatory">*</span>
                </label>
                <textarea
                  name="message"
                  id=""
                  cols="30"
                  rows={10}
                  placeholder="Type your message"
                  required
                />
              </div>
              <div
                ref={contactConSide}
                className={
                  contactConSideView ? "contact-btn" : "contact-btn-before"
                }
              >
                <button type="submit">Send</button>
              </div>
            </form>
            <div className="contact-details">
              <div
                ref={contactConSide}
                className={
                  contactConSideView
                    ? "contact-detail"
                    : "contact-detail-before"
                }
              >
                <i className="fa-solid fa-envelope"></i>
                <span> issy_02@hotmail.com</span>
              </div>
              <div
                ref={contactConSide}
                className={
                  contactConSideView
                    ? "contact-detail"
                    : "contact-detail-before"
                }
              >
                <i className="fa-solid fa-phone"></i>
                <span> +44 7449182436</span>
              </div>
              <div
                ref={contactConSide}
                className={
                  contactConSideView
                    ? "contact-detail"
                    : "contact-detail-before"
                }
              >
                <i className="fa-solid fa-location-dot"></i>
                <span> Leeds, UK</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
