import "./SocialNav.css";
import Pdf from "./Isfahan-Khan-CV.pdf";

function SocialNav() {
  return (
    <div className="conSocial">
      <div className="icons">
        <ul>
          <a
            href="https://www.github.com/Issy786"
            title="Github"
            target="_blank"
            rel="noreferrer"
          >
            <div className="git-blur">
              <li className="github">
                <i className="fa-brands fa-github"></i>
              </li>
            </div>
          </a>
          <a
            href="https://www.linkedin.com/in/isfahan-khan-46a68424a/"
            title="Linkedin"
            target="_blank"
            rel="noreferrer"
          >
            <div className="link-blur">
              <li className="linkedin">
                <i className="fa-brands fa-linkedin"></i>
              </li>
            </div>
          </a>
          <a href={Pdf} title="CV" target="_blank" rel="noreferrer">
            <div className="CV-blur">
              <li className="CV">
                <i className="fa-regular fa-file-lines"></i>
              </li>
            </div>
          </a>
        </ul>
      </div>
      <div className="line"></div>
    </div>
  );
}

export default SocialNav;
