import "./About.css";
import React from "react";
import { useInView } from "react-intersection-observer";

function About() {
  const { ref: textCon, inView: textView } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: backCon, inView: backView } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  // const { ref: backCover, inView: backCoverView } = useInView({
  //   threshold: 0.15,
  //   triggerOnce: true,
  // });

  const { ref: picCon, inView: picView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const { ref: aboutMe, inView: aboutMeView } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: aboutText, inView: aboutTextView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const { ref: aboutText2, inView: aboutTextView2 } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const { ref: aboutText3, inView: aboutTextView3 } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const { ref: techIcon, inView: techIconView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const { ref: techIcon2, inView: techIconView2 } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className="about-page">
      <div
        ref={textCon}
        className={textView ? "about-con-side" : "about-con-side-before"}
      >
        <div ref={backCon} className={backView ? "about-con" : "about-con"}>
          <div className="back-blurred">
            <p
              ref={aboutMe}
              className={aboutMeView ? "about-me show-text" : "about-me-before"}
            >
              ABOUT ME
            </p>

            <p
              ref={aboutText}
              className={aboutTextView ? "about-text" : "about-text-before"}
            >
              <span className="light-text">
                I enjoy creating software that takes User experience to the next
                level. My interest in software development started back in 2022
                when I decided to take on Harvard University's "CS50
                Introduction to Computer Science" online course, But I have
                always been a techie at heart.
              </span>
            </p>

            <p
              ref={aboutText2}
              className={
                aboutTextView2 ? "about-text-2" : "about-text-before-2"
              }
            >
              <span className="light-text">
                Fast-forward to today, and I have completed a <br />
              </span>
              <span className="show-text">
                Full Stack Software Development Bootcamp
              </span>
              <span className="light-text">
                {" "}
                in which I gained valuable experience and skills to use
                throughout{" "}
              </span>{" "}
              <span className="show-text">the full software lifecycle.</span>
              <span className="light-text">
                {" "}
                My main focus these days is building innovative projects and
                digital experiences with learning new technologies, developing
                my skillset and growing my network.
              </span>
            </p>

            <p
              ref={aboutText3}
              className={
                aboutTextView3
                  ? "about-text-3 tech show-text"
                  : "about-text-before-3 tech text"
              }
            >
              Here are a few technologies I’ve been working with recently:
            </p>
            <div className="tech-icons">
              <div
                ref={techIcon}
                className={techIconView ? "tech-icon" : "tech-icon-before"}
                id="JS"
              >
                <div className="flash-JS">
                  <i className="fa-brands fa-square-js"></i>
                  <p className="icon-text js-title">JavaScript</p>
                </div>
              </div>
              <div
                ref={techIcon}
                className={techIconView ? "tech-icon" : "tech-icon-before"}
                id="HTML"
              >
                <i className="fa-brands fa-html5"></i>
                <p className="icon-text">HTML5</p>
              </div>
              <div
                ref={techIcon}
                className={techIconView ? "tech-icon" : "tech-icon-before"}
                id="css"
              >
                <div className="flash-css">
                  <i className="fa-brands fa-css3-alt"></i>
                  <p className="icon-text">CSS3</p>
                </div>
              </div>
              <div
                ref={techIcon}
                className={techIconView ? "tech-icon" : "tech-icon-before"}
                id="react"
              >
                <i className="fa-brands fa-react"></i>
                <p className="icon-text">React</p>
              </div>
            </div>
            <div className="tech-icons">
              <div
                ref={techIcon2}
                className={techIconView2 ? "tech-icon-2" : "tech-icon-before-2"}
                id="node"
              >
                <i className="fa-brands fa-node"></i>
                <p className="icon-text">Node.js</p>
              </div>
              <div
                ref={techIcon2}
                className={techIconView2 ? "tech-icon-2" : "tech-icon-before-2"}
                id="sql"
              >
                <div className="flash-sql"></div>
                <i className="fa-solid fa-database"></i>
                <p className="icon-text">SQL</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={picCon}
        className={picView ? "about-pic-side" : "about-pic-side-before"}
      >
        <div className="pic-con" id="pic-float">
          <div className="pic-me"></div>
        </div>
      </div>
    </div>
  );
}

export default About;
