import { useState } from "react";
import About from "./About";
import "./App.css";
import Contact from "./Contact";
import Home from "./Home";
import NavBar from "./NavBar";
import Popup from "./Popup";
import Portfolio from "./Portfolio";
import SocialNav from "./SocialNav";
import Footer from "./footer";
import SideName from "./SideName";

function App() {
  const [buttonPopup, setButtonPopup] = useState(false);
  const [popupErr, setPopupErr] = useState(false);
  return (
    <div className="App">
      <NavBar />
      <SocialNav />
      <div id="/">
        <Home />
      </div>
      <div id="/about">
        <About />
      </div>
      <div className="space"></div>
      <div id="/portfolio">
        <Portfolio />
      </div>
      <div className="space"></div>
      <div id="/contact">
        <Contact setButtonPopup={setButtonPopup} setPopupErr={setPopupErr} />
        <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
          <i className="fa-solid fa-circle-check"></i>
          <div className="notice"> Message sent 📟</div>
        </Popup>
        <Popup trigger={popupErr} setTrigger={setPopupErr} popupErr={popupErr}>
          <i className="fa-solid fa-circle-xmark"></i>
          <div className="notice"> Message Failed! ⚠️</div>
        </Popup>
      </div>
      <Footer />
      <SideName />
    </div>
  );
}

export default App;
