import { useEffect, useState } from "react";
import "./SideName.css";

function SideName() {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.screen.width);
    };
    window.addEventListener("resize", handleResize);
  }, [screenWidth]);

  if (screenWidth < 1024) {
    return <div></div>;
  }

  return (
    <div className="conSideName">
      <div className="side-name-board">
        <p className="slide-from-left">I</p>
        <div className="flick">
          <p className="slide-from-left">S</p>
        </div>
        <p className="slide-from-left">F</p>
        <p className="slide-from-left">A</p>
        <p className="slide-from-left">H</p>
        <p className="slide-from-left">A</p>
        <div className="flick1">
          <p className="slide-from-left">N</p>
        </div>
        <p className="slide-from-left text-hidden">I</p>
        <p className="slide-from-left">K</p>
        <p className="slide-from-left">H</p>
        <div className="flick">
          <p className="slide-from-left">A</p>
        </div>
        <p className="slide-from-left">N</p>
      </div>
      <div className="holding-bars-con">
        <div className="holding-bar-con">
          <div className="holding-bar"></div>
        </div>
        <div className="holding-bar-con">
          <div className="holding-bar"></div>
        </div>
      </div>
    </div>
  );
}

export default SideName;
