import { useEffect, useState } from "react";
import "./Home.css";
import Typewriter from "typewriter-effect";

function Home() {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [rxRy, setRxRy] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.screen.width);
    };
    window.addEventListener("resize", handleResize);

    if (screenWidth > 1200) {
      setWidth(1200);
      setHeight(400);
      setRxRy(50);
    } else if (screenWidth <= 1200 && screenWidth > 1000) {
      setWidth(750);
      setHeight(300);
      setRxRy(50);
    } else if (screenWidth <= 1000 && screenWidth > 700) {
      setWidth(600);
      setHeight(220);
      setRxRy(40);
    } else if (screenWidth <= 700 && screenWidth > 390) {
      setWidth(370);
      setHeight(150);
      setRxRy(30);
    } else if (screenWidth <= 390) {
      setWidth(350);
      setHeight(150);
      setRxRy(30);
    }
  }, [screenWidth]);

  return (
    <div className="home">
      <div className="test1">
        <div className="test">
          <svg width={width} height={height}>
            <rect
              x="0"
              y="0"
              rx={rxRy}
              ry={rxRy}
              width={width}
              height={height}
            ></rect>
          </svg>
          <div className="p">
            <div className="swipe">
              <div className="big">
                IS<span className="flick">F</span>AHAN KH
                <span className="flick1">A</span>N
              </div>
            </div>
            <div className="swipe1">
              <div className="title">
                Sof<span className="flick1">t</span>ware D
                <span className="flick">e</span>velope
                <span className="flick1">r</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="intro">
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .pauseFor(5600)
                .typeString(
                  "Hi, my name is Isfahan Khan and my friends call me Issy. I am a creative software developer specializing in building and designing exceptional digital experiences. Currently, I'm focused on building accessible, human-centered projects."
                )
                .start();
            }}
            options={{
              delay: 20,
              cursorClassName: "cursor",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
