import React, { useRef, useEffect, useState } from "react";
import cyberCityPixel from "./cyber-city-pixel.mp4";
import cyberCityRain from "./cyber-city-rain.mp4";
import rainCity from "./rain-city.mp4";
import rainNightCity from "./rain-night-city.mp4";

function BackgroundVideo() {
  const videoRef = useRef();
  const [playCount, setPlayCount] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const [screenHeight, setScreenHeight] = useState(window.screen.height);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.screen.width);
      setScreenHeight(window.screen.height);
    };
    window.addEventListener("resize", handleResize);
  }, [screenWidth, screenHeight]);

  const handlePlay = () => {
    if (playCount < 3) {
      videoRef.current.play();
    }
  };

  const handleEnd = () => {
    if (playCount < 3) {
      setPlayCount(playCount + 1);
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  return (
    <div className="video-background">
      <video
        className="video"
        ref={videoRef}
        onEnded={handleEnd}
        onCanPlay={handlePlay}
        autoPlay
        muted
        playsInline
        src={
          screenWidth < 950 && screenHeight < 500
            ? rainCity
            : screenWidth < 1024
            ? cyberCityPixel
            : rainCity
        }
      ></video>

      <video
        className="video-2"
        autoPlay
        loop
        muted
        playsInline
        src={
          screenWidth < 950 && screenHeight < 500
            ? rainNightCity
            : screenWidth < 1024
            ? cyberCityRain
            : rainNightCity
        }
      ></video>
    </div>
  );
}

export default BackgroundVideo;
